const bodyCompositionData = [
    {
        key: 'tbw', // 唯一鍵，用於標識每個測量項目
        name: '身體總水重', // 項目的名稱
        unit: 'L', // 測量單位，例如升(L)、公斤(kg)
        minValue: 0, // 該項目測量值的最小值
        maxValue: 99, // 該項目測量值的最大值
        normalRange: [], // 該項目的正常範圍數值，通常是 [最小值, 最大值]
        minValueKey: '', // 最小值對應的鍵，通常用於後端返回的資料鍵
        maxValueKey: '', // 最大值對應的鍵，通常用於後端返回的資料鍵
        normalMinValueKey: 'tbwLower', // 正常範圍的最小值對應的鍵
        normalMaxValueKey: 'tbwUpper', // 正常範圍的最大值對應的鍵
    },
    {
        key: 'protein',
        name: '蛋白質重',
        unit: 'kg',
        minValue: 0,
        maxValue: 99,
        normalRange: [],
        minValueKey: '',
        maxValueKey: '',
        normalMinValueKey: 'proteinLower',
        normalMaxValueKey: 'proteinUpper',
    },
    {
        key: 'minerals',
        name: '礦物質重',
        unit: 'kg',
        minValue: 0,
        maxValue: 99,
        normalRange: [],
        minValueKey: '',
        maxValueKey: '',
        normalMinValueKey: 'mineralsLower',
        normalMaxValueKey: 'mineralsUpper',
    },
    {
        key: 'bfm',
        name: '體脂肪重',
        unit: 'kg',
        minValue: 0,
        maxValue: 99,
        normalRange: [],
        minValueKey: '',
        maxValueKey: '',
        normalMinValueKey: 'bfmLower',
        normalMaxValueKey: 'bfmUpper',
    },
    {
        key: 'slm',
        name: '肌肉量',
        unit: 'kg',
        minValue: 0,
        maxValue: 99,
        normalRange: [],
        minValueKey: '',
        maxValueKey: '',
        normalMinValueKey: 'slmLower',
        normalMaxValueKey: 'slmUpper',
    },
    {
        key: 'notBoneMineral',
        name: '非骨質',
        unit: 'kg',
        minValue: 0,
        maxValue: 99,
        normalRange: [],
        minValueKey: '',
        maxValueKey: '',
        normalMinValueKey: '',
        normalMaxValueKey: '',
    },
    {
        key: 'bmc',
        name: '骨質重',
        unit: 'kg',
        minValue: 0,
        maxValue: 99,
        normalRange: [],
        minValueKey: '',
        maxValueKey: '',
        normalMinValueKey: '',
        normalMaxValueKey: '',
    },
    {
        key: 'ffm',
        name: '除脂體重',
        unit: 'kg',
        minValue: 0,
        maxValue: 99,
        normalRange: [],
        minValueKey: '',
        maxValueKey: '',
        normalMinValueKey: 'ffmLower',
        normalMaxValueKey: 'ffmUpper',
    },
    {
        key: 'weight',
        name: '總體重',
        unit: 'kg',
        minValue: 0,
        maxValue: 99,
        normalRange: [],
        minValueKey: '',
        maxValueKey: '',
        normalMinValueKey: 'weightLower',
        normalMaxValueKey: 'weightUpper',
    },
];

export default bodyCompositionData;
