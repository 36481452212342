import React from 'react';
import { parseDate } from '@Util/moment';

const MainHeader = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="copyright ban-select">
                    {`版權所有 © ${parseDate(new Date(), 'YYYY')} Curves`}
                    <br />
                    可爾姿女性30分鐘環狀運動
                </div>
            </div>
        </footer>
    );
};

export default MainHeader;
