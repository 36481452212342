import React from 'react';
import { Box, Stack, TableCell, TableRow } from '@Components/';
import clsx from 'clsx';
import { Image } from '@Local/Components';

const DiseaseRiskAssessmentBox = (props) => {
    const { title, currentTitle, prevTitle, currentValue, prevValue, icon, notifyTitle, notifyValue, toFixedNum = 0 } = props;

    const formatDecimal = (value) => {
        return (toFixedNum === 0) ? Math.round(value).toString() : value.toFixed(toFixedNum).toString();
    };

    return (
        <>
            <TableRow className={'disease-risk-assessment-table__title'}>
                <TableCell colSpan={'2'}>{title}</TableCell>
            </TableRow>
            <TableRow className={'disease-risk-assessment-table__subtitle'}>
                <TableCell>{currentTitle}</TableCell>
                <TableCell>{prevTitle}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>{formatDecimal(currentValue)}</TableCell>
                <TableCell>{formatDecimal(prevValue)}</TableCell>
            </TableRow>
            <TableRow className={'disease-risk-assessment-table__icon'}>
                <TableCell colSpan={'2'} className={clsx({ highlight: notifyValue })}>
                    <Stack spacing={1} alignItems={'center'} justifyContent={'center'}>
                        {notifyValue ? <Image className="icon" src={icon} /> : <span> - </span>}
                        <Box>
                            {notifyTitle}
                            <small> (潛在風險)</small>
                        </Box>
                    </Stack>
                </TableCell>
            </TableRow>
        </>
    );
};

export default DiseaseRiskAssessmentBox;
