import React from 'react';
import clsx from 'clsx';
import { TableCell, TableRow } from '@Components/';

const EvaluationSummaryBox = (props) => {
    const { title, currentTitle, prevTitle, currentValue, prevValue, compareValue, notifyValue } = props;

    return (
        <>
            <TableRow className={'evaluation-summary-table__title'}>
                <TableCell colSpan={'3'}>{title}</TableCell>
            </TableRow>
            <TableRow className={'evaluation-summary-table__subtitle'}>
                <TableCell>{currentTitle}</TableCell>
                <TableCell>{prevTitle}</TableCell>
                <TableCell>比較</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>{currentValue}</TableCell>
                <TableCell>{prevValue}</TableCell>
                <TableCell className={clsx({ highlight: notifyValue })}>
                    <span>{compareValue}</span>
                </TableCell>
            </TableRow>
        </>
    );
};

export default EvaluationSummaryBox;
