const weightControlData = [
    {
        key: 'targetWeight',
        name: '目標體重',
        unit: 'kg',
    },
    {
        key: 'weightControl',
        name: '體重控制',
        unit: 'kg',
    },
    {
        key: 'bfmControl',
        name: '脂肪控制',
        unit: 'kg',
    },
    {
        key: 'ffmControl',
        name: '肌肉控制',
        unit: 'kg',
    },
];

export default weightControlData;
