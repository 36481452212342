import React, { useEffect, useState } from 'react';
import useAppPrivacyApi from '@Apis/useAppPrivacyApi';
import { localUser } from '@Util/utils';
import PrivacyPolicyDialog from '@Local/PrivacyPolicy/PrivacyPolicyDialog';

const UsePrivacyPolicy = () => {
    const user = localUser.get();
    const { getPrivacyApi } = useAppPrivacyApi();
    const [open, setOpen] = useState(false);

    const doPrivacyApi = async () => {
        const resp = await getPrivacyApi();
        setOpen(!Boolean(resp));
    };

    const onClose = () => {
        setOpen(false);
    };

    useEffect(
        () => {
            if (Boolean(user)) doPrivacyApi();
        },
        // eslint-disable-next-line
        [],
    );

    return open ? <PrivacyPolicyDialog open={open} onClose={onClose} /> : null;
};

export default UsePrivacyPolicy;
