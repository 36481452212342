import React from 'react';
import clsx from 'clsx';

const Image = ({ src, alt, className }) => {
    return (
        <div className={clsx(className, 'img-wrap')}>
            <img src={src} alt={alt} />
        </div>
    );
};

export default Image;
