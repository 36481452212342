import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@Components/';
import {
    BodyCompositionTable,
    CIDBox,
    MeasurementTitle,
    MeasurementItem,
    PartAnalysisSection,
    BodyTypeMapBox,
    InbodyScoreBox,
} from '@Local/Measurement/Components/';
import {
    bodyCompositionData,
    obesityAnalysisData,
    weightControlData,
    bodyEquilibriumData,
    bodyMeasurementsData,
    researchParametersData,
} from '@Local/Measurement/data/';
import { useMeasurementContext } from '@Local/Measurement/Components/MeasurementContext/MeasurementContext';
import useMemberInbodyApi from '@Apis/useMemberInbodyApi';
import { isEmpty } from '@Util/utils';
import { DocumentFile as DocumentFileIcon } from '@SvgIcon/';
import { FloatButton } from '@Local/Components';
import measureDisplayMode from '@Local/Measurement/data/measureDisplayMode';

const MeasurementResultsPage = () => {
    const { measureID } = useMeasurementContext();
    const { getMemberInbodyResultApi, getMemberInbodyResultPageApi } = useMemberInbodyApi();
    const [data, setData] = useState(null);
    const [pageData, setPageData] = useState(null);
    const [displayMode, setDisplayMode] = useState(measureDisplayMode.graph);
    const iframeRef = useRef();

    const mergeRemoteData = (itemData = {}, remoteData = {}) => {
        const _itemData = { ...itemData };
        const normalMinValue = remoteData[_itemData?.normalMinValueKey];
        const normalMaxValue = remoteData[_itemData?.normalMaxValueKey];
        const minValue = remoteData[_itemData?.minValueKey];
        const maxValue = remoteData[_itemData?.maxValueKey];
        const value = remoteData[_itemData?.key];

        if (normalMinValue && normalMaxValue) {
            _itemData.normalRange = [normalMinValue, normalMaxValue];
        }
        if (minValue) {
            _itemData.minValue = minValue;
        }
        if (maxValue) {
            _itemData.maxValue = maxValue;
        }
        if (value || typeof value === 'number') {
            _itemData.value = String(value);
        }

        return _itemData;
    };

    const doMemberInbodyResultApi = async () => {
        const resp = await getMemberInbodyResultApi(measureID);
        if (resp) {
            setData(resp);
        }
    };

    const doMemberInbodyResultPageApi = async () => {
        const resp = await getMemberInbodyResultPageApi(measureID);
        if (resp) {
            setPageData(resp);
        }
    };

    const handleToggleDisplayMode = () => {
        setDisplayMode((prev) => (prev === measureDisplayMode.graph ? measureDisplayMode.text : measureDisplayMode.graph));
    };

    const parseMeasurementDataEvalString = (val) => {
        switch (String(val)) {
            case '0':
                return '平衡';
            case '1':
                return '輕度失衡';
            case '2':
                return '嚴重失衡';
            default:
                return '未知';
        }
    };

    const handleResizeFrame = () => {
        const iframe = iframeRef.current;
        if (iframe && iframe.contentWindow) {
            iframe.style.width = `${iframe.contentWindow.document.body.scrollWidth + 20}px`;
            iframe.style.height = `${iframe.contentWindow.document.body.scrollHeight + 20}px`;
        }
    };

    useEffect(
        () => {
            if (measureID) {
                displayMode === measureDisplayMode.text ? doMemberInbodyResultApi(measureID) : doMemberInbodyResultPageApi(measureID);
            }
        },
        // eslint-disable-next-line
        [measureID, displayMode],
    );

    console.log(pageData)
    
    
    return (
        <>
            {displayMode === measureDisplayMode.text && !isEmpty(data) && (
                <Box className={'container'}>
                    <Box className={'measurement-group'}>
                        <MeasurementTitle title={'身體組成分析'} />
                        {bodyCompositionData.map((item, index) => {
                            const itemData = mergeRemoteData(item, data);
                            return <MeasurementItem key={index} {...itemData} />;
                        })}
                    </Box>
                    <Box className={'measurement-group'}>
                        <MeasurementTitle title={'肌肉脂肪分析'} />
                        <CIDBox sourceData={data} className={'pb-2'} />
                    </Box>
                    <Box className={'measurement-group'}>
                        <MeasurementTitle title={'肥胖分析'} />
                        {obesityAnalysisData.map((item, index) => {
                            const itemData = mergeRemoteData(item, data);
                            return <MeasurementItem key={index} {...itemData} />;
                        })}
                    </Box>
                    <Box className={'measurement-group pb-2'}>
                        <MeasurementTitle title={'部位分析'} />
                        <PartAnalysisSection className={'px-2'} sourceData={data} />
                    </Box>
                    <Box className="px-2 pt-2">
                        <InbodyScoreBox score={data?.inbodyScore} />
                        <Typography className={'tip'} variant={'body2'}>
                            ※總評分反應身體組成的評估值，肌肉發達的人得分可能超過100分
                        </Typography>
                    </Box>
                    <Box className={'measurement-group pb-2'}>
                        <MeasurementTitle title={'身體類型'} />
                        <BodyTypeMapBox bmi={data?.bmi} pbf={data?.pbf} />
                    </Box>
                    <Box className={'measurement-group'}>
                        <MeasurementTitle title={'體重控制'} />
                        {weightControlData.map((item, index) => {
                            const itemData = mergeRemoteData(item, data);
                            return <MeasurementItem key={index} {...itemData} />;
                        })}
                    </Box>
                    <Box className={'measurement-group'}>
                        <MeasurementTitle title={'身體平衡'} />
                        {bodyEquilibriumData.map((item, index) => {
                            const itemData = mergeRemoteData(item, data);
                            itemData.value = parseMeasurementDataEvalString(itemData.value);
                            return <MeasurementItem className={'body-equilibrium-item'} key={index} {...itemData} />;
                        })}
                    </Box>
                    <Box className={'measurement-group'}>
                        <MeasurementTitle title={'部位別圍度'} />
                        {bodyMeasurementsData.map((item, index) => {
                            const itemData = mergeRemoteData(item, data);
                            return <MeasurementItem key={index} {...itemData} />;
                        })}
                    </Box>
                    <Box className={'measurement-group'}>
                        <MeasurementTitle title={'研究參數'} />
                        {researchParametersData.map((item, index) => {
                            const itemData = mergeRemoteData(item, data);
                            return <MeasurementItem key={index} {...itemData} />;
                        })}
                    </Box>
                    <Box className={'measurement-group'}>
                        <MeasurementTitle title={'身體組成歷程記錄'} />
                        <BodyCompositionTable className={'px-3 pb-2'} sourceData={data?.measureHistoryList} />
                    </Box>
                </Box>
            )}
            {displayMode === measureDisplayMode.graph && !isEmpty(pageData) && (
                <Box className={'container-full page-center'}>
                    <iframe ref={iframeRef} title={'InBody 套表'} src={pageData.pageUrl} onLoad={handleResizeFrame} />
                </Box>
            )}
            <FloatButton tip={'查看量身套表'} onClick={handleToggleDisplayMode}>
                <DocumentFileIcon htmlColor={'#fff'} />
            </FloatButton>
        </>
    );
};

export default MeasurementResultsPage;
