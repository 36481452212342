import React from 'react';
import { MenuItem, SelectField } from '@Components/';

const SelectWithOptions = ({ refItem, label, defaultValue, options = [], fullWidth = false, displayEmpty = false, required, ...other }) => {
    return (
        <SelectField key={defaultValue} ref={refItem} label={label} defaultValue={defaultValue} fullWidth={fullWidth} required={required} {...other}>
            {displayEmpty && (
                <MenuItem value="">
                    <em>請選擇</em>
                </MenuItem>
            )}
            {Array.isArray(options) &&
                options.map(({ text, value }) => (
                    <MenuItem key={value} value={value}>
                        {text}
                    </MenuItem>
                ))}
        </SelectField>
    );
};

export default SelectWithOptions;
