/*
    新增頁面 ADD
    /XXX/XXX/add
    編輯頁面 EDIT
    /XXX/XXX/edit/(:id)
    唯獨模式 READ ONLY
    /XXX/XXX/read-only/(:id)
*/

/* 登陸與ROOT */
export const ENTRANCE_ROOT_ROUTER = `member`;
export const LOGIN_ROOT_ROUTER = `/login/`;
export const AUTH_ROOT_ROUTER = `/auth/`;
export const AUTH_SINGLE_ROOT_ROUTER = `/auth-single/`;
export const MEMBER_ROOT_ROUTER = `/member/`;

/* 特殊單頁 ROOT - 不需登入的成績單頁 */
export const SINGLE_TRANSCRIPT_ROOT_ROUTER = `/member/single-transcripts`;

// export const ECOMMERCE_ROOT_ROUTER = `/ec`;

export const MEASURE_ROUTER = `/member/measure/`;
export const MEASURE_RESULT_ROUTER = `/member/measure/result/`;
export const MEASURE_TRANSCRIPT_ROUTER = `/member/measure/transcript/`;
export const MEASURE_EVALUATION_ROUTER = `/member/measure/evaluation/`;
