import { useCallback } from 'react';
import useAxios from '@Apis/useAxios';
import { GET_PRIVACY } from '@Apis/apiPath';

const UseAppPrivacyApi = () => {
    const Axios = useAxios();

    // 隱私權資訊
    const getPrivacyApi = useCallback(
        async () => {
            return await Axios.get(GET_PRIVACY);
        },
        // eslint-disable-next-line
        [],
    );

    // 我同意按鈕
    const postPrivacyApi = useCallback(
        async () => {
            return await Axios.post(GET_PRIVACY);
        },
        // eslint-disable-next-line
        [],
    );

    return { getPrivacyApi, postPrivacyApi };
};

export default UseAppPrivacyApi;
