import React from 'react';
// import '../../scss/Error/_notFoundPage.scss';

const staticClass = 'not-found-page';

const NotFoundPage = () => {
    const startCount = 80;
    const craterPoint = 6;

    return (
        <div className={`${staticClass}-root`}>
            <div className={`${staticClass}-stars`}>
                {Array(startCount)
                    .fill()
                    .map((x, idx) => (
                        <div className={`${staticClass}-star`} key={idx} />
                    ))}
            </div>
            <div className={`${staticClass}-center`}>
                <div className={`${staticClass}-circle ${staticClass}-circle-outer`}></div>
                <div className={`${staticClass}-circle ${staticClass}-circle-inner`}>
                    <div className={`${staticClass}-four four-1`}>4</div>
                    <div className={`${staticClass}-four four-2`}>4</div>
                    <div className={`${staticClass}-circle ${staticClass}-oh`}>
                        <div className={`${staticClass}-circle planet`}>
                            <div className="craters">
                                {Array(craterPoint)
                                    .fill()
                                    .map((x, idx) => (
                                        <div className={`crater crater-${idx}`} key={idx} />
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${staticClass}-sorry`}>Oops! 抱歉，找不到網頁位置</div>
            </div>
        </div>
    );
};

export default NotFoundPage;
