import React from 'react';
import { Box } from '@Components/';
import { Image } from '@Local/Components';
import body_girl from '@Images/body-girl.png';
import legsImbalance from '@Images/body-girl-bottom.png';
import leftLegImbalance from '@Images/body-girl-bottom-left.png';
import rightLegImbalance from '@Images/body-girl-bottom-right.png';
import armsImbalance from '@Images/body-girl-top.png';
import leftArmsImbalance from '@Images/body-girl-top-left.png';
import rightArmsImbalance from '@Images/body-girl-top-right.png';
import { isArray } from 'lodash';

const MeasurementLimbMuscles = ({ balancedImageNames, balancedLimbsTexts, balancedBodyTexts }) => {
    const balancedImageMap = {
        legsImbalance,
        leftLegImbalance,
        rightLegImbalance,
        armsImbalance,
        leftArmsImbalance,
        rightArmsImbalance,
    };

    const pascalToCamelCase = (str) => {
        if (!str) return str; // 確保字串不為空
        return str.charAt(0).toLowerCase() + str.slice(1);
    }
    
    return (
        <Box className={'measurement-limb-muscles'}>
            <Box className={'measurement-limb-muscles__container'}>
                <Box className={'measurement-limb-muscles__image'}>
                    <Box className="measurement-limb-muscles__image-wrap">
                        <Image src={body_girl} />
                        {isArray(balancedImageNames) &&
                            balancedImageNames.map((name) => <Image key={name} className={'overlay-img'} src={balancedImageMap[pascalToCamelCase(name)]} />)}
                    </Box>
                </Box>
                <Box className={'measurement-limb-muscles__content'}>
                    {balancedBodyTexts.length > 0 && (
                        <p>
                            {balancedBodyTexts.map((text, i) => (
                                <React.Fragment key={i}>
                                    {i !== 0 && <br />}
                                    {text}
                                </React.Fragment>
                            ))}
                        </p>
                    )}
                    {balancedLimbsTexts.length > 0 && (
                        <p>
                            {balancedLimbsTexts.map((text, i) => (
                                <React.Fragment key={i}>
                                    {i !== 0 && <br />}
                                    {text}
                                </React.Fragment>
                            ))}
                        </p>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default MeasurementLimbMuscles;
