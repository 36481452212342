import React, { useLayoutEffect } from 'react';
import { Switch, useLocation, useHistory } from 'react-router-dom';
import { MessageDialog } from '@Local/Components/';
import { NotFoundPage } from '@Local/Error/';
import { PrivateRoute } from '@Components/';
import { MEMBER_ROOT_ROUTER } from '@Router/routerPath';
import LandingPage from '@Local/Landing/';
import PrivacyPolicy from "@Local/PrivacyPolicy/PrivacyPolicy";

const Home = () => {
    const { replace: routeReplace } = useHistory();
    const { pathname: rootPath = '' } = useLocation();

    useLayoutEffect(
        () => {
            const splitPath = rootPath.split('/');
            if (splitPath.length === 2 && splitPath.filter((x) => x).length === 0) {
                routeReplace(MEMBER_ROOT_ROUTER);
            } else if (splitPath.includes('index') || splitPath.includes('index.html')) {
                routeReplace('/');
            }
        },
        // eslint-disable-next-line
        [rootPath, routeReplace],
    );

    return (
        <React.Fragment>
            <Switch>
                <PrivateRoute static path={MEMBER_ROOT_ROUTER} as={LandingPage} />
                <PrivateRoute path="*" as={NotFoundPage} />
            </Switch>
            <PrivacyPolicy />
            <MessageDialog />
        </React.Fragment>
    );
};

export default Home;
