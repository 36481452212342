const researchParametersData = [
    {
        key: 'vfl',
        name: '內臟脂肪級別',
        unit: '',
        minValue: 0,
        maxValue: 99,
        normalRange: [1, 9],
        minValueKey: '',
        maxValueKey: '',
        normalMinValueKey: 'vflLower',
        normalMaxValueKey: 'vflUpper',
    },
    {
        key: 'whr',
        name: '腰臀圍比',
        unit: '',
        minValue: 0,
        maxValue: 1,
        normalRange: [0.75, 0.85],
        minValueKey: '',
        maxValueKey: '',
        normalMinValueKey: 'whrLower',
        normalMaxValueKey: 'whrUpper',
    },
    {
        key: 'smi',
        name: '肌肉質量指數',
        unit: 'kg/m2',
        normalRange: [],
        minValue: 0,
        maxValue: 99,
        minValueKey: '',
        maxValueKey: '',
        normalMinValueKey: '',
        normalMaxValueKey: '',
    },
    {
        key: 'bmc',
        name: '骨礦含量',
        unit: 'L',
        normalRange: [],
        minValue: 0,
        maxValue: 99,
        minValueKey: '',
        maxValueKey: '',
        normalMinValueKey: '',
        normalMaxValueKey: '',
    },
    {
        key: 'bmr',
        name: '基礎代謝率',
        unit: 'kcal',
        normalRange: [],
        minValue: 0,
        maxValue: 99,
        minValueKey: '',
        maxValueKey: '',
        normalMinValueKey: '',
        normalMaxValueKey: '',
    },
    {
        key: 'recommendedCaloricIntake',
        name: '建議攝取熱量',
        unit: 'Kcal',
        normalRange: [],
        minValue: 0,
        maxValue: 99,
        minValueKey: '',
        maxValueKey: '',
        normalMinValueKey: '',
        normalMaxValueKey: '',
    },
];

export default researchParametersData;
