/* 登入 */
export const GET_AUTH_API = `/api/auth/`;
export const GET_LOGIN_API = `/api/login/`;
export const GET_LOGOUT_API = `/api/logout/`;

/* 版本號 */
export const GET_SYSTEM_BUILD_TIME_API = `/api/system/build-time/`;

/* 身份驗證 */
export const GET_ME_CHECK_API = `/api/me/check/`;
export const GET_ME_API = `/api/me/`;

/* 量測 */
export const GET_MEMBER_INBODY_OPTIONS = `/api/member/inbody/options/`;
export const GET_MEMBER_INBODY_RESULT = (measureID) => `/api/member/inbody/${measureID}/`;
export const GET_MEMBER_INBODY_RESULT_PAGE = (measureID) => `/api/member/inbody/${measureID}/info-page`;
export const GET_MEMBER_INBODY_TRANSCRIPT_PAGE = (measureID) => `/api/member/inbody/${measureID}/transcript-page`;
export const GET_MEMBER_SINGLE_TRANSCRIPT_PAGE = (tokenKey) => `/api/member/inbody/${tokenKey}/single-transcript-page`;
export const GET_MEMBER_MEASURE_BODY_EVALUATION = (measureID) => `/api/member/inbody/${measureID}/evaluation`;
export const GET_MEMBER_MEASURE_BODY_EVALUATION_PAGE = (measureID) => `/api/member/inbody/${measureID}/evaluation-page`;

export const GET_PRIVACY = `/api/privacy/`;
