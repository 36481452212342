import React from 'react';
import clsx from 'clsx';

const InbodyScoreBox = ({ className, score = 0 }) => {
    return (
        <div className={clsx('inbody-score-box', className)}>
            <h4 className={'inbody-score-box__title'}>Inbody 分數</h4>
            <p className={'inbody-score-box__value'}>
                <span className={'inbody-score-box__score'}>{score}</span>
                <span>分</span>
            </p>
        </div>
    );
};

export default InbodyScoreBox;
