import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { Button, Typography } from '@Components/';
import useForkRef from '@Util/hook/useForkRef';
import { getMessageDialogData } from '@Redux/messageDialog/selector';
import DialogTitleHeader from './DialogTitleHeader';

const dialogWidthMap = {
    samll: 'wx-40rem',
    medium: 'wx-65rem',
    large: 'wx-90rem',
};

const MessageDialog = () => {
    const [openDialog, setOpenDialog] = useState(false);
    const messageData = useSelector(getMessageDialogData);
    const { open, title, msg, MsgComp, msgProps, msgRef, onClose, onConfirm, onAsyncConfirm, onOpen, buttonText = '確定', dialogWidth = 'small' } = messageData;
    const childRef = useRef(null);
    const forkRef = useForkRef(msgRef, childRef);
    let paperClass;
    if (dialogWidthMap[dialogWidth]) {
        paperClass = dialogWidthMap[dialogWidth];
    }
    const handleOpen = (e) => {
        setOpenDialog(true);
        onOpen && onOpen();
    };

    const handleClose = (e) => {
        onClose && onClose(e);
        setOpenDialog(false);
    };

    const handleConfirm = (e) => {
        if (onAsyncConfirm) {
            onAsyncConfirm(e, () => setOpenDialog(false));
        } else {
            onConfirm && onConfirm();
            setOpenDialog(false);
        }
    };

    useEffect(() => {
        if (open) {
            handleOpen();
        } else {
            handleClose();
        }
        // eslint-disable-next-line
    }, [messageData]);

    if (!openDialog) return null;
    if (msgRef) {
        msgProps.ref = forkRef;
    }

    return (
        <Dialog className={clsx('simple-content', 'dialog')} PaperProps={{ className: paperClass }} open={openDialog} fullWidth>
            <DialogTitleHeader onClose={handleClose}>{title}</DialogTitleHeader>
            <DialogContent>
                {msg && <Typography paragraph>{msg}</Typography>}
                {MsgComp && React.cloneElement(<MsgComp {...msgProps} />)}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleConfirm}>
                    {buttonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MessageDialog;
