import React from 'react';
import clsx from "clsx";
import { getRandomInt } from "@Util/utils";
import LoadingText from "./LoadingText";

// size: 1~5
// spritesIndex : 1.Bicep, 2.Squat, 3.LegPress
const sprintSize = [
    { width: 0, height: 0 },
    { width: 334, height: 3350 },
    { width: 540, height: 3350 },
    { width: 743, height: 3350 }
];

const SportsEquipmentLoading = ({ text, size, spritesIndex }) => {
    let num = (size && size >= 1 && size <= 5) ? Math.round(size) : 1;
    let currentScale = .5 + (.1 * (num - 1));
    let currentIndex = (spritesIndex && spritesIndex >= 1 && spritesIndex <= 3) ? Math.round(spritesIndex) : (getRandomInt(2) + 1);

    const { width: bkW, height: bkH } = sprintSize[currentIndex];
    const innerW = bkW * currentScale;
    const innerH = bkH / 5 * currentScale;

    return (
        <div className="loading-sports-root">
            <div className={clsx('loading-sports-item', `loading-sports-item-${currentIndex}`)} style={{
                backgroundSize: `${innerW}px ${innerH * 5}px`,
                width: `${innerW}px`,
                height: `${innerH}px`
            }}></div>
            <br/>
            <LoadingText text={text}/>
        </div>
    );
};

export default SportsEquipmentLoading;
