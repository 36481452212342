import React from 'react';

const LoadingText = ({ text }) => {
    if (!text) return null; 
    
    return (
        <div className="loading-text">
            <span>{text}</span><span className="dots"></span>
        </div>
    );
};

export default LoadingText;
