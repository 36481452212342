import React, { useEffect, useRef, useState } from 'react';
import { Dialog, DialogContent, Grid } from '@mui/material';
import { Box, Button, CheckboxField } from '@Components/';
import UseAppPrivacyApi from '@Apis/useAppPrivacyApi';

const PrivacyPolicyDialog = ({ open = true, onClose }) => {
    const boxRef = useRef(null);
    const iframeRef = useRef(null);
    const confirmRef = useRef(null);
    const { postPrivacyApi } = UseAppPrivacyApi();
    const [hide, setHide] = useState(true);
    const [btnStatus, setBtnStatus] = useState(false);

    const doPrivacyApi = async () => {
        await postPrivacyApi();
        if (typeof onClose === 'function') {
            onClose();
        }
    };
    
    const handleChangeAllow = () => {
        setBtnStatus(confirmRef.current.getResult && confirmRef.current.getResult()[0].checked);
    };

    const handleClick = () => {
        if (btnStatus) {
            doPrivacyApi();
        }
    };

    const handleIframeScroll = () => {
        const iframeDocument = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
        const scrollTop = iframeDocument.documentElement.scrollTop || iframeDocument.body.scrollTop;
        const clientHeight = iframeDocument.documentElement.clientHeight;
        const scrollHeight = iframeDocument.documentElement.scrollHeight;

        if (scrollTop + clientHeight >= scrollHeight) {
            setHide(false);
        }
    };

    const handleIframeLoad = () => {
        const iframeDocument = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;

        if (iframeDocument) {
            iframeDocument.addEventListener('scroll', handleIframeScroll);
        }
    };

    useEffect(
        () => {
            const iframeDocument = iframeRef.current?.contentDocument || iframeRef.current?.contentWindow?.document;

            return () => {
                if (iframeDocument) {
                    iframeDocument.removeEventListener('scroll', handleIframeScroll);
                }
            };
        },
        // eslint-disable-next-line
        [],
    );

    return (
        <Dialog className={'privacy-policy-dialog'} open={open} fullScreen>
            <DialogContent className={'p-0'}>
                <Box ref={boxRef} className="iframe-wrap" style={{ height: 'calc(100vh - 140px)' }}>
                    <iframe ref={iframeRef} title={'會員隱私權政策'} src={'./privacy-policy'} onLoad={handleIframeLoad} />
                </Box>
                <Box className={'privacy-policy-dialog__footer'}>
                    <Grid spacing={2} container>
                        <Grid xs={12} item>
                            <CheckboxField ref={confirmRef} required disabled={hide}>
                                <CheckboxField.Checkbox value={true} label={'本人同意以上會員隱私權政策'} required onChange={handleChangeAllow} />
                            </CheckboxField>
                        </Grid>
                        <Grid xs={12} item>
                            <Box className={'text-center'}>
                                <Button variant={'contained'} disabled={!btnStatus} onClick={handleClick} fullWidth>
                                    確定
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default PrivacyPolicyDialog;
