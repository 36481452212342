import React from 'react';
import logo_img from '@Images/logo.svg';
import LogoutButton from '@Local/Components/LogoutButton/LogoutButton';
import { localUser, isEmpty } from '@Util/utils';
import { Link } from 'react-router-dom';
import { MEMBER_ROOT_ROUTER } from '@Router/routerPath';

const MainHeader = () => {
    const user = localUser.get();

    return (
        <header className={'header'}>
            <div className={'container'}>
                <Link to={MEMBER_ROOT_ROUTER}>
                    <div className={'header--logo'}>
                        <img src={logo_img} alt={'Curves'} />
                    </div>
                </Link>
                {!isEmpty(user) && (
                    <div className="header--user">
                        <img src="/api/me/user.jpg" alt={user.displayName} />
                        <span>{user.displayName}</span>
                    </div>
                )}
                <div className={'header--nav'}>
                    <LogoutButton />
                </div>
            </div>
        </header>
    );
};

export default MainHeader;
