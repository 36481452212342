import React from 'react';
import { Tablenization } from '@Local/Components';
import clsx from 'clsx';
import { parseDate } from '@Util/moment';
import { DateTimeStandard } from '../../../../Components/pickers/enums/DateTimeStandard';
import { Box } from '@Components/';

const headerRow = [
    {
        cellKey: 'measureDate',
        headerLabel: '測量日',
        align: 'center',
        formatCell: (value) => {
            return (
                <>
                    <Box>{parseDate(value, DateTimeStandard.DisplayDateBySlash)}</Box>
                    <Box className={'font-color-3'}>{parseDate(value, DateTimeStandard.DisplayTime)}</Box>
                </>
            );
        },
    },
    {
        cellKey: 'weight',
        headerLabel: '體重',
        align: 'center',
        formatCell: (value) => {
            return `${value}kg`;
        },
    },
    {
        cellKey: 'smm',
        headerLabel: '骨骼肌重',
        align: 'center',
        formatCell: (value) => {
            return `${value}kg`;
        },
    },
    {
        cellKey: 'pbf',
        headerLabel: '體脂肪率',
        align: 'center',
        formatCell: (value) => {
            return `${value}%`;
        },
    },
];

const BodyCompositionTable = ({ className, sourceData }) => {
    return (
        <Tablenization cardProps={{ className: clsx('body-composition-table', className) }}>
            <Tablenization.TableRowHeader headerRow={headerRow} />
            <Tablenization.TableRowBody headerRow={headerRow} rowsData={sourceData} />
        </Tablenization>
    );
};

export default BodyCompositionTable;
