import { useLayoutEffect } from 'react'
import useAuthApi from '@Apis/useAuthApi';
import { useHistory, useLocation } from "react-router-dom";
import { localUser } from "@Util/utils";
import { LOGIN_ROOT_ROUTER, SINGLE_TRANSCRIPT_ROOT_ROUTER } from "@Router/routerPath";

const AuthPage = () => {
    const { getCheckLocalDataApi, getLogoutApi } = useAuthApi();
    const { pathname: rootPath = '', search } = useLocation();
    const query = new URLSearchParams(search);
    const history = useHistory();

    const resetToken = async (tokenKey, nextPath) => {
        const hasLogin = await getCheckLocalDataApi();
        if (hasLogin) {
            await getLogoutApi(() => {
                localUser.setToken(tokenKey);
                history.push(nextPath);
            });
        }
        else {
            localUser.setToken(tokenKey);
            history.push(nextPath);
        }
        
    };

    useLayoutEffect(
        () => {
            const key = query.get('t');
            const splitPathname = rootPath.split('/');
            if (splitPathname.length > 1 && key !== '') {
                const nextPath = splitPathname[1] === 'auth'
                    ? LOGIN_ROOT_ROUTER
                    : SINGLE_TRANSCRIPT_ROOT_ROUTER;

                resetToken(key, nextPath);
            }
        },
        // eslint-disable-next-line
        [rootPath],
    );

    return null;
};

export default AuthPage;