import React from 'react';
import clsx from 'clsx';
import { DialogTitle } from '@mui/material';
import { IconButton } from '@Components/';
import { Close as CloseIcon } from '@SvgIcon/';

const DialogTitleHeader = (props) => {
    const { className, title: propsTitle = '請設定標題', onClose: onCloseProps, children } = props;

    const handleOnClose = (e) => {
        onCloseProps && onCloseProps(e);
    };
    return (
        <DialogTitle className="dialog-title-header">
            <div className={clsx(className, onCloseProps && 'dialog-title-header__text')}>{children || propsTitle}</div>
            {onCloseProps && (
                <div className="dialog-title-header__close">
                    <IconButton size="large" onClick={handleOnClose}>
                        <CloseIcon
                        // htmlColor="#fff"
                        />
                    </IconButton>
                </div>
            )}
        </DialogTitle>
    );
};

export default DialogTitleHeader;
