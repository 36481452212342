import React from 'react';
import inbody_female_img from '@Images/inbody-female.png';
import clsx from 'clsx';

const Item = ({ className, weight, proportion, status }) => {
    return (
        <div className={clsx('part-analysis-box__data-item', className)}>
            <p>{weight ? `${weight}kg` : '沒有數據'}</p>
            <p>{proportion ? `${proportion}%` : '沒有數據'}</p>
            <p>{status ? status : '沒有數據'}</p>
        </div>
    );
};

const PartAnalysisBox = ({ data = {} }) => {
    const defaultItems = [
        { key: 'armLeft', className: 'arm-left' },
        { key: 'armRight', className: 'arm-right' },
        { key: 'legLeft', className: 'leg-left' },
        { key: 'legRight', className: 'leg-right' },
        { key: 'abdomen', className: 'abdomen' },
    ];

    return (
        <div className={'part-analysis-box'}>
            <img className={'part-analysis-box__img'} src={inbody_female_img} alt={"inbody"}/>
            <div className={'part-analysis-box__content'}>
                {defaultItems.map(({ key, className }) => {
                    return <Item key={key} className={className} weight={data[key]?.weight} proportion={data[key]?.proportion} status={data[key]?.status} />;
                })}
            </div>
        </div>
    );
};

export default PartAnalysisBox;
