import React from 'react';
import { Table, TableBody } from '@Components/';
import DiseaseRiskAssessmentBox from '@Local/Measurement/Components/DiseaseRiskAssessmentTable/DiseaseRiskAssessmentBox';
import icon_1 from '@Images/icon-disease-1.png';
import icon_2 from '@Images/icon-disease-2.png';
import icon_3 from '@Images/icon-disease-3.png';
import icon_4 from '@Images/icon-disease-4.png';

const DiseaseRiskAssessmentTable = ({ currentTitle, prevTitle, measureSourceData, prevMeasureSourceData, compareData }) => {
    const titleComponent_1 = (
        <>
            內臟脂肪 <small>(1 - 9)</small>
        </>
    );
    const titleComponent_2 = (
        <>
            腰臀圍比 <small>(0.75 - 0.85)</small>
        </>
    );
    const titleComponent_3 = (
        <>
            肌肉質量指數 SMI{' '}
            <small>
                (≥ 5.7kg/m<sup>2</sup>)
            </small>
        </>
    );
    const titleComponent_4 = (
        <>
            骨礦含量 <small>(2.22 - 2.72)</small>
        </>
    );

    return (
        <Table className={'disease-risk-assessment-table'}>
            <TableBody>
                <DiseaseRiskAssessmentBox
                    title={titleComponent_1}
                    currentTitle={currentTitle}
                    prevTitle={prevTitle}
                    currentValue={measureSourceData?.vfl}
                    prevValue={prevMeasureSourceData?.vfl}
                    icon={icon_1}
                    notifyTitle={'代謝症候群'}
                    notifyValue={compareData?.notifyVFL}
                    toFixedNum={0}
                />
                <DiseaseRiskAssessmentBox
                    title={titleComponent_2}
                    currentTitle={currentTitle}
                    prevTitle={prevTitle}
                    currentValue={measureSourceData?.whr}
                    prevValue={prevMeasureSourceData?.whr}
                    icon={icon_2}
                    notifyTitle={'心血管疾病'}
                    notifyValue={compareData?.notifyWHR}
                    toFixedNum={2}
                />
                <DiseaseRiskAssessmentBox
                    title={titleComponent_3}
                    currentTitle={currentTitle}
                    prevTitle={prevTitle}
                    currentValue={measureSourceData?.skeletalMuscleIndex}
                    prevValue={prevMeasureSourceData?.skeletalMuscleIndex}
                    icon={icon_3}
                    notifyTitle={'肌少症'}
                    notifyValue={compareData?.notifySMI}
                    toFixedNum={1}
                />
                <DiseaseRiskAssessmentBox
                    title={titleComponent_4}
                    currentTitle={currentTitle}
                    prevTitle={prevTitle}
                    currentValue={measureSourceData?.bmc}
                    prevValue={prevMeasureSourceData?.bmc}
                    icon={icon_4}
                    notifyTitle={'骨質疏鬆'}
                    notifyValue={compareData?.notifyBMC}
                    toFixedNum={2}
                />
            </TableBody>
        </Table>
    );
};

export default DiseaseRiskAssessmentTable;
