import React, { useState, useEffect } from 'react';
import Loading from "./Loading";
import SportsEquipmentLoading from "./SportsEquipmentLoading";
import useForkRef from '@Util/hook/useForkRef';

const ImagePreLoading = React.forwardRef((
    { src, alt, loadingText, useSportsEquipment }, ref) => {
    const imgRef = React.useRef(null);
    const combinedRef = useForkRef(ref, imgRef);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const img = new Image();
        img.src = src;
        img.onload = () => setIsLoading(false);
    }, [src]);

    return (
        <>
            {isLoading ? (
                useSportsEquipment
                    ? <SportsEquipmentLoading size={1} spritesIndex={1} text={loadingText} />
                    : <div className="text-center" style={{ height: `100px` }}>
                        <Loading size={50} duration={700} />
                    </div>
            ) : (
                <img src={src} alt={alt} ref={combinedRef} />
            )}
        </>
    );
});

export default ImagePreLoading;