import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@Components/';
import { isEmpty } from '@Util/utils';
import measureDisplayMode from '@Local/Measurement/data/measureDisplayMode';
import { MeasurementBodyChart, MeasurementLimbMuscles, MeasurementTitle } from '@Local/Measurement/Components';
import { FloatButton } from '@Local/Components';
import { DocumentFile as DocumentFileIcon } from '@SvgIcon/';
import useMemberInbodyApi from '@Apis/useMemberInbodyApi';
import { useMeasurementContext } from '@Local/Measurement/Components/MeasurementContext/MeasurementContext';
import { parseDate } from '@Util/moment';
import { EvaluationSummaryTable } from '@Local/Measurement/Components/EvaluationSummaryTable';
import { DiseaseRiskAssessmentTable } from '@Local/Measurement/Components/DiseaseRiskAssessmentTable';

const MeasurementEvaluationPage = () => {
    const { measureID } = useMeasurementContext();
    const { getMemberMeasureBodyEvaluationApi, getMemberMeasureBodyEvaluationPageApi } = useMemberInbodyApi();
    const [sourceData, setSourceData] = useState(null);
    const [pageData, setPageData] = useState(null);
    const [displayMode, setDisplayMode] = useState(measureDisplayMode.text);
    const iframeRef = useRef();

    const doMemberMeasureBodyEvaluationApi = async () => {
        const resp = await getMemberMeasureBodyEvaluationApi(measureID);
        if (resp) {
            setSourceData(resp);
        }
    };

    const doMemberMeasureBodyEvaluationPageApi = async (measureID) => {
        const resp = await getMemberMeasureBodyEvaluationPageApi(measureID);
        if (resp) {
            setPageData(resp);
        }
    };

    const handleToggleDisplayMode = () => {
        setDisplayMode((prev) => (prev === measureDisplayMode.graph ? measureDisplayMode.text : measureDisplayMode.graph));
    };

    const handleResizeFrame = () => {
        const iframe = iframeRef.current;
        if (iframe && iframe.contentWindow) {
            iframe.style.width = `${iframe.contentWindow.document.body.scrollWidth + 20}px`;
            iframe.style.height = `${iframe.contentWindow.document.body.scrollHeight + 20}px`;
        }
    };

    const handleMemberMeasureBodyEvaluation = useCallback(
        (measureID) => {
            // 資料幾乎不會變動，所以只在第一次取得時呼叫 API
            if (isEmpty(sourceData)) {
                doMemberMeasureBodyEvaluationApi(measureID);
            }
        },
        // eslint-disable-next-line
        [sourceData],
    );

    const handleMemberMeasureBodyEvaluationPage = useCallback(
        (measureID) => {
            // 資料幾乎不會變動，所以只在第一次取得時呼叫 API
            if (isEmpty(pageData)) {
                doMemberMeasureBodyEvaluationPageApi(measureID);
            }
        },
        // eslint-disable-next-line
        [pageData],
    );

    useEffect(
        () => {
            if (measureID) {
                displayMode === measureDisplayMode.text ? handleMemberMeasureBodyEvaluation(measureID) : handleMemberMeasureBodyEvaluationPage(measureID);
            }
        },
        // eslint-disable-next-line
        [measureID, displayMode],
    );

    let currentTitle, prevTitle;
    if (!isEmpty(sourceData)) {
        currentTitle = parseDate(sourceData?.measureSourceData?.measureDate, 'M月');
        prevTitle = parseDate(sourceData?.prevMeasureSourceData?.measureDate, 'M月');

        if (currentTitle === prevTitle) {
            currentTitle = parseDate(sourceData?.measureSourceData?.measureDate, 'M月D日');
            prevTitle = parseDate(sourceData?.prevMeasureSourceData?.measureDate, 'M月D日');
        }
    }

    return (
        <>
            {displayMode === measureDisplayMode.text && !isEmpty(sourceData) && (
                <Box className={'container measurement-transcript'}>
                    <Box className={'measurement-group px-3 pb-3'}>
                        <MeasurementTitle title={'綜合數據評估'} />
                        <EvaluationSummaryTable
                            currentTitle={currentTitle}
                            prevTitle={prevTitle}
                            measureSourceData={sourceData?.measureSourceData}
                            prevMeasureSourceData={sourceData?.prevMeasureSourceData}
                            compareData={sourceData?.compareData}
                        />
                    </Box>
                    <Box className={'measurement-group px-3 pb-3'}>
                        <MeasurementTitle title={'疾病風險評估'} />
                        <Typography className={'font-color-3'} variant={'body2'}>
                            以下相關數據不能作為疾病的診斷；如有疑慮，建議須再諮詢相關專業醫師。
                        </Typography>
                        <DiseaseRiskAssessmentTable
                            currentTitle={currentTitle}
                            prevTitle={prevTitle}
                            measureSourceData={sourceData?.measureSourceData}
                            prevMeasureSourceData={sourceData?.prevMeasureSourceData}
                            compareData={sourceData?.compareData}
                        />
                    </Box>
                    <Box className={'measurement-group px-3 pb-3'}>
                        <MeasurementTitle title={'曲線變化評估'} />
                        <MeasurementBodyChart
                            notifyNeckMC={sourceData?.compareData?.notifyNeckMC}
                            notifyArmMC={sourceData?.compareData?.notifyArmMC}
                            notifyChestMC={sourceData?.compareData?.notifyChestMC}
                            notifyAbdomenMC={sourceData?.compareData?.notifyAbdomenMC}
                            notifyHipMC={sourceData?.compareData?.notifyHipMC}
                            notifyThighMC={sourceData?.compareData?.notifyThighMC}
                            signNeckMC={sourceData?.compareData?.signNeckMC}
                            signArmMC={sourceData?.compareData?.signArmMC}
                            signChestMC={sourceData?.compareData?.signChestMC}
                            signAbdomenMC={sourceData?.compareData?.signAbdomenMC}
                            signHipMC={sourceData?.compareData?.signHipMC}
                            signThighMC={sourceData?.compareData?.signThighMC}
                        />
                    </Box>
                    <Box className={'measurement-group px-3 pb-3'}>
                        <MeasurementTitle title={'身體平衡評估'} />
                        <MeasurementLimbMuscles
                            balancedImageNames={sourceData?.compareData?.balancedImageNames}
                            balancedLimbsTexts={sourceData?.compareData?.balancedLimbsTexts}
                            balancedBodyTexts={sourceData?.compareData?.balancedBodyTexts}
                        />
                    </Box>
                </Box>
            )}
            {displayMode === measureDisplayMode.graph && !isEmpty(pageData) && (
                <Box className={'container-full page-center'}>
                    <iframe ref={iframeRef} title={'InBody 身體評估表'} src={pageData.pageUrl} onLoad={handleResizeFrame} />
                </Box>
            )}
            <FloatButton tip={'查看量身套表'} onClick={handleToggleDisplayMode}>
                <DocumentFileIcon htmlColor={'#fff'} />
            </FloatButton>
        </>
    );
};

export default MeasurementEvaluationPage;
