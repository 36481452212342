import { useCallback } from 'react';
import useAxios from '@Apis/useAxios';
import {
    GET_MEMBER_INBODY_OPTIONS,
    GET_MEMBER_INBODY_RESULT,
    GET_MEMBER_INBODY_RESULT_PAGE,
    GET_MEMBER_INBODY_TRANSCRIPT_PAGE,
    GET_MEMBER_SINGLE_TRANSCRIPT_PAGE,
    GET_MEMBER_MEASURE_BODY_EVALUATION,
    GET_MEMBER_MEASURE_BODY_EVALUATION_PAGE
} from '@Apis/apiPath';

const useMemberInbodyApi = () => {
    const Axios = useAxios();

    const getMemberInbodyOptionsApi = useCallback(
        async () => {
            const resp = await Axios.get(GET_MEMBER_INBODY_OPTIONS);
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    const getMemberInbodyResultApi = useCallback(
        async (measureID) => {
            const resp = await Axios.get(GET_MEMBER_INBODY_RESULT(measureID));
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    const getMemberInbodyResultPageApi = useCallback(
        async (measureID) => {
            const resp = await Axios.get(GET_MEMBER_INBODY_RESULT_PAGE(measureID));
            return resp;
        },
        // eslint-disable-next-line
        [],
    );
    
    const getMemberInbodyTranscriptPageApi = useCallback(
        async (measureID) => {
            const resp = await Axios.get(GET_MEMBER_INBODY_TRANSCRIPT_PAGE(measureID));
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    const getMemberSingleTranscriptPageApi = useCallback(
        async (tokenKey) => {
            const resp = await Axios.get(GET_MEMBER_SINGLE_TRANSCRIPT_PAGE(tokenKey));
            console.log(resp);
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    const getMemberMeasureBodyEvaluationApi = useCallback(
        async (measureID) => {
            const resp = await Axios.get(GET_MEMBER_MEASURE_BODY_EVALUATION(measureID));
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    const getMemberMeasureBodyEvaluationPageApi = useCallback(
        async (measureID) => {
            const resp = await Axios.get(GET_MEMBER_MEASURE_BODY_EVALUATION_PAGE(measureID));
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    return {
        getMemberInbodyOptionsApi,
        getMemberInbodyResultApi,
        getMemberInbodyResultPageApi,
        getMemberInbodyTranscriptPageApi,
        getMemberSingleTranscriptPageApi,
        getMemberMeasureBodyEvaluationApi,
        getMemberMeasureBodyEvaluationPageApi
    };
};

export default useMemberInbodyApi;
