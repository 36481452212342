import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, ImagePreLoading } from '@Components/';
import { useMeasurementContext } from '@Local/Measurement/Components/MeasurementContext/MeasurementContext';
import useMemberInbodyApi from "@Apis/useMemberInbodyApi";
import { isEmpty } from '@Util/utils';

const MeasurementTranscriptPage = () => {
    const { measureID } = useMeasurementContext();
    const { getMemberInbodyTranscriptPageApi } = useMemberInbodyApi();
    const [pageData, setPageData] = useState(null);
    const iframeClubRef = useRef();
    const iframeAgeRef = useRef();

    const doTranscriptResultPageApi = async () => {
        const resp = await getMemberInbodyTranscriptPageApi(measureID);
        if (resp) {
            setPageData(resp);
        }
    };

    const handleResizeFrame = (ref) => {
        const iframe = ref.current;
        if (iframe && iframe.contentWindow) {
            iframe.style.width = `${iframe.contentWindow.document.body.scrollWidth + 20}px`;
            iframe.style.height = `${iframe.contentWindow.document.body.scrollHeight + 20}px`;
        }
    }


    useEffect(
        () => {
            if (measureID) {
                doTranscriptResultPageApi(measureID);
            }
        },
        // eslint-disable-next-line
        [measureID],
    );

    if (isEmpty(pageData)) return null;

    let { hasData, useImage, clubRankingUrl, ageGroupUrl } = pageData;

    return (
        <>
            {!hasData && <Box className={'container'}>
                <Box className={'px-3 py-2'}>查無相關資料</Box>
            </Box>}
            {hasData && <Box className={'container-full page-center'}>
                {useImage ? <>
                    <Typography variant={'body2'} className={'px-3 font-color-3'}>
                        ※ 提醒：對圖片長按可儲存到您的裝置
                    </Typography>
                    {clubRankingUrl !== '' && <ImagePreLoading src={clubRankingUrl} alt={'店舖成績單'} loadingText={'成績單產生中'} useSportsEquipment />}
                    <p></p>
                    {ageGroupUrl !== '' && <ImagePreLoading src={ageGroupUrl} alt={'店舖成績單'} loadingText={'成績單產生中'} useSportsEquipment />}
                </> : <>
                    {clubRankingUrl !== '' && <iframe
                        ref={iframeClubRef}
                        title={'店舖成績單'}
                        src={clubRankingUrl}
                        onLoad={() => handleResizeFrame(iframeClubRef)}
                    />}
                    <br />
                    {ageGroupUrl !== '' && <iframe
                        ref={iframeAgeRef}
                        title={'分年齡成績單'}
                        src={ageGroupUrl}
                        onLoad={() => handleResizeFrame(iframeAgeRef)}
                    />}
                </>}
            </Box>}
        </>


    );
};

export default MeasurementTranscriptPage;
