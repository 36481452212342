import React from 'react';
import MainHeader from '@Local/Components/Header/MainHeader';
import MainFooter from '@Local/Components/Footer/MainFooter';

const MainLayout = ({ children }) => {
    return (
        <>
            <MainHeader />
            <div className={'main-container'}>{children}</div>
            <MainFooter />
        </>
    );
};

export default MainLayout;
