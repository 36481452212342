import React from 'react';

/**
 * BRA顏色對應表
 *
 * 根據測量值的狀態（正常或警告）映射到對應的顏色。
 */

const colorMap = {
    normal: '#66bb6a',
    warning: '#f44336',
};

/**
 * 計算進度百分比
 *
 * 根據當前測量值、正常範圍以及最小和最大可能值來計算進度條的百分比。
 * 進度條分為三個部分：
 * - 未達標：占進度條的前20%
 * - 正常：占進度條的20%到40%
 * - 超標：占進度條的40%到100%
 *
 * @param {number|string} value - 當前測量值，可以是數字或字符串類型
 * @param {Array<number>} normalRange - 正常範圍的數值數組 [最小值, 最大值]
 * @param {number} minValue - 測量值的最小可能值
 * @param {number} maxValue - 測量值的最大可能值
 *
 * @returns {number} 進度條的百分比（0到100之間）
 */

export const calculatePercentage = (value, normalRange, minValue, maxValue) => {
    const numericValue = parseFloat(value);
    if (numericValue < normalRange[0]) {
        return (numericValue / normalRange[0]) * 20;
    } else if (numericValue >= normalRange[0] && numericValue <= normalRange[1]) {
        return 20 + ((numericValue - normalRange[0]) / (normalRange[1] - normalRange[0])) * 20;
    } else {
        return 40 + ((numericValue - normalRange[1]) / (maxValue - normalRange[1])) * 60;
    }
};

/**
 * MeasurementBar 組件
 *
 * 用於顯示測量值的進度條，根據給定的範圍和當前值來計算進度百分比，
 * 並以不同的顏色顯示進度條的狀態（正常或警告）。
 *
 * @param {number} value - 當前測量值
 * @param {Array<number>} normalRange - 正常範圍的數值數組 [最小值, 最大值]
 * @param {number} minValue - 測量值的最小可能值
 * @param {number} maxValue - 測量值的最大可能值
 * @param {boolean} [showLabels=false] - 是否顯示標籤（默認為不顯示）
 * @param {boolean} [showTicks=true] - 是否顯示刻度（默認為顯示）
 *
 * @returns {JSX.Element} 測量進度條的 JSX 元素
 */

const MeasurementBar = ({ value, normalRange, minValue, maxValue, showLabels = false, showTicks = true }) => {
    const percentage = calculatePercentage(value, normalRange, minValue, maxValue);
    const sections = Array.from({ length: 10 }, (_, index) => index + 1);

    return (
        <div className="measurement-bar">
            <div className="measurement-bar__background">
                <div
                    className="measurement-bar__foreground"
                    style={{
                        width: `${percentage}%`,
                        backgroundColor: percentage >= 20 && percentage <= 40 ? colorMap['normal'] : colorMap['warning'],
                    }}
                ></div>
            </div>
            {showTicks && (
                <div className="measurement-bar__sections-wrap">
                    {sections.map((section) => (
                        <div key={section} className="measurement-bar__section"></div>
                    ))}
                </div>
            )}
            {showLabels && (
                <div className="measurement-bar__labels">
                    <span className="measurement-bar__label measurement-bar__label--left">未達標</span>
                    <span className="measurement-bar__label measurement-bar__label--middle">正常</span>
                    <span className="measurement-bar__label measurement-bar__label--right">超標</span>
                </div>
            )}
        </div>
    );
};

export default MeasurementBar;
