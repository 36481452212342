import React from 'react';
import { Table, TableBody } from '@Components/';
import EvaluationSummaryBox from '@Local/Measurement/Components/EvaluationSummaryTable/EvaluationSummaryBox';

const EvaluationSummaryTable = ({ currentTitle, prevTitle, measureSourceData, prevMeasureSourceData, compareData }) => {
    const titleComponent_1 = <>月運動總次數</>;
    const titleComponent_2 = (
        <>
            InBody評分 <small>(分)</small>
        </>
    );
    const titleComponent_3 = (
        <>
            骨骼肌重 <small>(kg)</small>
        </>
    );
    const titleComponent_4 = (
        <>
            體脂肪率 <small>(%)</small>
        </>
    );
    const titleComponent_5 = (
        <>
            體重 <small>(kg)</small>
        </>
    );
    const titleComponent_6 = (
        <>
            總體圍 <small>(cm)</small>
        </>
    );

    return (
        <Table className={'evaluation-summary-table'}>
            <TableBody>
                <EvaluationSummaryBox
                    title={titleComponent_1}
                    currentTitle={currentTitle}
                    prevTitle={prevTitle}
                    currentValue={measureSourceData?.count}
                    prevValue={prevMeasureSourceData?.count}
                    compareValue={compareData?.signCount}
                    notifyValue={compareData?.notifyCount}
                />
                <EvaluationSummaryBox
                    title={titleComponent_2}
                    currentTitle={currentTitle}
                    prevTitle={prevTitle}
                    currentValue={measureSourceData?.inbodyScore}
                    prevValue={prevMeasureSourceData?.inbodyScore}
                    compareValue={compareData?.signInbodyScore}
                    notifyValue={compareData?.notifyInbodyScore}
                />
                <EvaluationSummaryBox
                    title={titleComponent_3}
                    currentTitle={currentTitle}
                    prevTitle={prevTitle}
                    currentValue={measureSourceData?.smm}
                    prevValue={prevMeasureSourceData?.smm}
                    compareValue={compareData?.signSMM}
                    notifyValue={compareData?.notifySMM}
                />
                <EvaluationSummaryBox
                    title={titleComponent_4}
                    currentTitle={currentTitle}
                    prevTitle={prevTitle}
                    currentValue={measureSourceData?.pbf}
                    prevValue={prevMeasureSourceData?.pbf}
                    compareValue={compareData?.signPBF}
                    notifyValue={compareData?.notifyPBF}
                />
                <EvaluationSummaryBox
                    title={titleComponent_5}
                    currentTitle={currentTitle}
                    prevTitle={prevTitle}
                    currentValue={measureSourceData?.weight}
                    prevValue={prevMeasureSourceData?.weight}
                    compareValue={compareData?.signWeight}
                    notifyValue={compareData?.notifyWeight}
                />
                <EvaluationSummaryBox
                    title={titleComponent_6}
                    currentTitle={currentTitle}
                    prevTitle={prevTitle}
                    currentValue={measureSourceData?.ac}
                    prevValue={prevMeasureSourceData?.ac}
                    compareValue={compareData?.signAC}
                    notifyValue={compareData?.notifyAC}
                />
            </TableBody>
        </Table>
    );
};

export default EvaluationSummaryTable;
