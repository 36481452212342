import React from 'react';
import clsx from 'clsx';
import { Box } from '@Components/';
import { Image } from '@Local/Components';
import { MeasurementItem } from '@Local/Measurement/Components';

import body_girl from '@Images/body-girl.png';

const MeasurementBodyChart = ({
    signNeckMC,
    signArmMC,
    signChestMC,
    signAbdomenMC,
    signHipMC,
    signThighMC,
    notifyNeckMC,
    notifyArmMC,
    notifyChestMC,
    notifyAbdomenMC,
    notifyHipMC,
    notifyThighMC,
}) => {
    const measurementItems = [
        { key: 'signNeckMC', name: '頸部', comparisonResult: signNeckMC, isProgressing: notifyNeckMC },
        { key: 'signArmMC', name: '手臂', comparisonResult: signArmMC, isProgressing: notifyArmMC },
        { key: 'signChestMC', name: '胸部', comparisonResult: signChestMC, isProgressing: notifyChestMC },
        { key: 'signAbdomenMC', name: '腰部', comparisonResult: signAbdomenMC, isProgressing: notifyAbdomenMC },
        { key: 'signHipMC', name: '臀部', comparisonResult: signHipMC, isProgressing: notifyHipMC },
        { key: 'signThighMC', name: '大腿', comparisonResult: signThighMC, isProgressing: notifyThighMC },
    ];
    return (
        <Box className={'measurement-body-chart'}>
            <Box className={'measurement-body-chart__container'}>
                <Image className={'measurement-body-chart__image'} src={body_girl} />
                <Box className={'measurement-body-chart__content'}>
                    {measurementItems.map(({ key, name, comparisonResult, isProgressing }) => {
                        return <MeasurementItem key={key} className={clsx({ highlight: isProgressing })} name={name} value={comparisonResult} />;
                    })}
                </Box>
                <Box className={'measurement-body-chart__line-group'}>
                    {measurementItems.map(({ key, isProgressing }) => {
                        return <Box key={key} className={clsx('measurement-body-chart__line', { highlight: isProgressing })} />;
                    })}
                </Box>
            </Box>
        </Box>
    );
};

export default MeasurementBodyChart;
