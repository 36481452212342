export const obesityAnalysisData = [
    {
        key: 'bmi',
        name: 'BMI',
        unit: 'kg/m2',
        minValue: 0,
        maxValue: 99,
        normalRange: [],
        minValueKey: '',
        maxValueKey: '',
        normalMinValueKey: 'bmiLower',
        normalMaxValueKey: 'bmiUpper',
    },
    {
        key: 'bfm',
        name: '體脂肪',
        unit: 'L',
        minValue: 0,
        maxValue: 99,
        normalRange: [],
        minValueKey: '',
        maxValueKey: '',
        normalMinValueKey: 'bfmLower',
        normalMaxValueKey: 'bfmUpper',
    },
];

export default obesityAnalysisData;
