import React, { useState } from 'react';
import PartAnalysisBox from './PartAnalysisBox';
import clsx from 'clsx';

const PART_TYPE_MUSCLE = 'muscle';
const PART_TYPE_FAT = 'fat';

const PartAnalysisSection = ({ className, sourceData }) => {
    const {
        leftArmFFM,
        leftArmFFMPercentage,
        leftArmFFMEval,
        rightArmFFM,
        rightArmFFMPercentage,
        rightArmFFMEval,
        leftLegFFM,
        leftLegFFMPercentage,
        leftLegFFMEval,
        rightLegFFM,
        rightLegFFMPercentage,
        rightLegFFMEval,
        trunkFFM,
        truckFFMPercentage,
        trunkFFMEval,
        leftArmBFM,
        leftArmBFMPercentage,
        leftArmFatEval,
        rightArmBFM,
        rightArmBFMPercentage,
        rightArmFatEval,
        leftLegBFM,
        leftLegBFMPercentage,
        leftLegFatEval,
        rightLegBFM,
        rightLegBFMPercentage,
        rightLegFatEval,
        trunkBFM,
        trunkBFMPercentage,
        trunkFatEval,
    } = sourceData || {};
    const [selectedPartType, setSelectedPartType] = useState(PART_TYPE_MUSCLE);

    const handlePartTypeSwitch = (type) => {
        setSelectedPartType(type);
    };

    const parseFatAndMuscleEvalString = (val) => {
        switch (String(val)) {
            case '0':
                return '低';
            case '1':
                return '正常';
            case '2':
                return '高';
            default:
                return '未知';
        }
    };

    const muscleData = {
        armLeft: { weight: leftArmFFM, proportion: leftArmFFMPercentage, status: parseFatAndMuscleEvalString(leftArmFFMEval) },
        armRight: { weight: rightArmFFM, proportion: rightArmFFMPercentage, status: parseFatAndMuscleEvalString(rightArmFFMEval) },
        legLeft: { weight: leftLegFFM, proportion: leftLegFFMPercentage, status: parseFatAndMuscleEvalString(leftLegFFMEval) },
        legRight: { weight: rightLegFFM, proportion: rightLegFFMPercentage, status: parseFatAndMuscleEvalString(rightLegFFMEval) },
        abdomen: { weight: trunkFFM, proportion: truckFFMPercentage, status: parseFatAndMuscleEvalString(trunkFFMEval) },
    };

    const fatData = {
        armLeft: { weight: leftArmBFM, proportion: leftArmBFMPercentage, status: parseFatAndMuscleEvalString(leftArmFatEval) },
        armRight: { weight: rightArmBFM, proportion: rightArmBFMPercentage, status: parseFatAndMuscleEvalString(rightArmFatEval) },
        legLeft: { weight: leftLegBFM, proportion: leftLegBFMPercentage, status: parseFatAndMuscleEvalString(leftLegFatEval) },
        legRight: { weight: rightLegBFM, proportion: rightLegBFMPercentage, status: parseFatAndMuscleEvalString(rightLegFatEval) },
        abdomen: { weight: trunkBFM, proportion: trunkBFMPercentage, status: parseFatAndMuscleEvalString(trunkFatEval) },
    };

    const data = selectedPartType === PART_TYPE_MUSCLE ? muscleData : fatData;

    return (
        <div className={clsx('part-analysis-section', className)}>
            <div className={'part-analysis-section__controller'}>
                <button className={clsx({ active: selectedPartType === PART_TYPE_MUSCLE })} onClick={() => handlePartTypeSwitch(PART_TYPE_MUSCLE)}>
                    肌肉量
                </button>
                <button className={clsx({ active: selectedPartType === PART_TYPE_FAT })} onClick={() => handlePartTypeSwitch(PART_TYPE_FAT)}>
                    脂肪
                </button>
            </div>
            <PartAnalysisBox data={data} />
        </div>
    );
};
export default PartAnalysisSection;
