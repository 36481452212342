import React from 'react';
import { IconButton, Tooltip } from '@Components/';
import clsx from 'clsx';

const FloatButton = ({ children, ...props }) => {
    const { className, tip, onClick } = props;

    return (
        <Tooltip title={tip}>
            <IconButton {...props} className={clsx('float-button', className)} onClick={onClick}>
                {children}
            </IconButton>
        </Tooltip>
    );
};

export default FloatButton;
