import React from 'react';
import { MeasurementBar } from '@Local/Measurement/Components';
import clsx from 'clsx';

const MeasurementItem = ({ className, name, value = 0, unit, minValue = 0, maxValue = 99, normalRange = [] }) => {
    const isDisplayBar = normalRange.length > 0;

    return (
        <div className={clsx('measurement-item', className)}>
            <h5 className="measurement-item__title">{name}</h5>
            <div className="measurement-item__wrap">
                <div className="measurement-item__value measurement-item__value--normal">
                    {value}
                    {unit && <span className="measurement-item__value-unit">{unit}</span>}
                </div>
                {isDisplayBar && <MeasurementBar value={value} normalRange={normalRange} minValue={minValue} maxValue={maxValue} showLabels />}
            </div>
        </div>
    );
};

export default MeasurementItem;
